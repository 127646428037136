import BaseApiServices from "@/services/BaseApiServices";

class QuizAPIService extends BaseApiServices{


    async getMyQuiz({ signal = null }) {

        const url = `games/quiz/getMyQuiz`;

        const data = await this.getAxiosInstance().get(url, {signal});

        return data;
    }

}

export default new QuizAPIService();
