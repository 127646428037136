<template>
    <v-container>
        <base-card>
            <v-card-title class="justify-center">
                <span class="text-h5 text-center"> {{ $t('games.quiz.title') }}</span>
            </v-card-title>

            <v-card-text
                class="mt-0 pt-0"
                style="min-height: 50vh"
            >

                <div>
                    <v-data-iterator
                        :items="itemsToShowWithGroupTitle"
                        :items-per-page.sync="itemsPerPage"
                        :page.sync="page"
                        hide-default-footer
                        :loading="loadingItems"
                        :loading-text="$t('common.loading')"
                    >

                        <template v-slot:default="props">
                            <v-row>
                                <v-col
                                    v-for="item in props.items"
                                    :key="item.id"
                                    cols="12"
                                >

                                    <template v-if="item.groupTitle" >
                                        <span class="mx-4" v-text="item.groupTitle" />

                                        <v-divider class="mb-3 mx-4"/>
                                    </template>

                                    <!--                                            { "game_id": 27, "points": 1, "time": 431795, "started_at": "2022-11-25 13:07:32", "finished_at": "2022-11-30 13:04:07", "closed": 0, "ends_at": "2022-12-31 23:30:00", "game_type_id": 2 }-->
                                    <div class="quiz--item" :class="itemRowClass(item)"
                                         @click="openItem(item)"
                                    >
                                        <div class="quiz--item__picture">
                                            <v-img
                                                :src="getImageURL(item)"
                                                contain
                                                class="my-1"
                                            />
                                            <span v-if="$vuetify.breakpoint.width < 600"
                                                  class="text-post"
                                                  v-text="item.name"/>
                                        </div>
                                        <div class="quiz--item__info">
                                            <span v-if="$vuetify.breakpoint.width >= 600"
                                                  class="text-post"
                                                  v-text="item.name"/>

                                            <div class="text-small">
                                                <div style="min-width: 14.5rem">
                                                    <span>{{ $t('games.ends_at') + ':' }} <strong
                                                        v-text="item.ends_at"/></span>
                                                    <span>{{ $t('games.started_at') + ':' }} <strong
                                                        v-text="item.started_at"/></span>
                                                    <span>{{ $t('games.finished_at') + ':' }} <strong
                                                        v-text="item.finished_at"/></span>
                                                </div>

                                                <div>
                                                    <span style="display: inline">{{
                                                                                      $t('games.ranking.points') + ':'
                                                                                  }}
                                                        <v-chip
                                                            style="display: inline-flex"
                                                            :color="getPointsColor(item)"
                                                            dark
                                                        >
                                                            {{ getPointsText(item) }}
                                                        </v-chip>
                                                    </span>
                                                    <span>{{ $t('games.ranking.time') + ':' }} <strong
                                                        v-text="getTimeForHumansString(item.time)"/></span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="quiz--item__actions">

                                            <v-icon
                                                class="quiz--item__actions-progress"
                                                size="54">
                                                {{ getIconProgress(item) }}
                                            </v-icon>
                                            <c-btn
                                                style="justify-self: right; align-self: center;"
                                                icon
                                                x-large
                                                mdi-icon="mdi-poll"
                                                @click.stop="goToRanking(item)"
                                            />
                                        </div>
                                    </div>

                                </v-col>
                            </v-row>
                        </template>

                        <template v-slot:no-data>
                            <div class="d-flex flex-column align-center text-gray-600 mt-6">
                                <span class="text-h6" v-html="$t('games.quiz.emptyList.title')" />

                                <div class="d-flex flex-column align-center text-subtitle-1">
                                    <div v-html="$t('games.quiz.emptyList.data')"/>
                                </div>
                            </div>
                        </template>

                        <template
                            v-if="items && items.length"
                            v-slot:footer
                        >
                            <v-row
                                class="mt-2 ml-0 mr-0"
                                align="center"
                                justify="center"
                            >
                                <span class="grey--text">{{ $t('common.itemsPerPage') }}</span>
                                <v-menu offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            dark
                                            text
                                            color="primary"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            {{ itemsPerPage }}
                                            <v-icon>mdi-chevron-down</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item
                                            v-for="(number, index) in itemsPerPageArray"
                                            :key="index"
                                            @click="updateItemsPerPage(number)"
                                        >
                                            <v-list-item-title>{{ number }}</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>

                                <v-spacer/>

                                <div class="d-flex flex-wrap">
                                    <v-pagination
                                        v-model="page"
                                        :length="numberOfPages"
                                    />
                                </div>
                            </v-row>
                        </template>


                    </v-data-iterator>
                </div>

            </v-card-text>

            <nextPostBtn :url-next-post="nextPost"/>
        </base-card>


    </v-container>
</template>

<script>
/* import axios from '@/plugins/axios' */
import {mapGetters} from 'vuex'
import postMixin from "@/mixins/postMixin";
import nextPostBtn from '@/components/posts/nextPostBtn'
import QuizAPIService from "@/services/QuizAPIService";
import dateTimeMixin from "@/mixins/dateTimeMixin";
import itemPointsMixin from "@/mixins/itemPointsMixin";

export default {
    name: 'Quiz',
    components: {nextPostBtn},
    mixins: [postMixin, dateTimeMixin, itemPointsMixin],
    data: function () {
        return {
            items: [],
            itemsPerPageArray: [5, 10, 50, 100],
            search: '',
            filter: {},
            page: 1,
            itemsPerPage: 5,
            loadingItems: true
        }
    },
    computed: {
        itemsToShowWithGroupTitle(){

            let finishedFound = false;


            const items = this.customSort.map( item => {
                if(!finishedFound){
                    if(item.finished_at) {
                        item.groupTitle = this.$t('games.quiz.done');
                        finishedFound = true;
                    }
                }
                return item;
            });

            if(items.length){
                if(!items[0].finished_at) {
                    items[0].groupTitle = this.$t('games.quiz.undone');
                }
            }

            return items;
        },
        customSort() {
            const items = this.dataFilter;

            const indexA = 'finished_at';
            const indexB = 'ends_at';

            items.sort((a, b) => {

                let p1 = a[indexA] || '9000';
                let p2 = b[indexA] || '9000';

                if(p1 === p2){
                    // ordered from smallest to largest (empty to end)
                    let s1 = a[indexB] || '9000';
                    let s2 = b[indexB] || '9000';

                    return  s1 > s2 ? 1 : -1;
                }

                // ordered from largest to smallest (empty to beginning)
                return p1 > p2 ? -1 : 1;

            })

            // console.table('customSort table', items)
            // console.log('customSort', items)

            return items
        },
        dataFilter() {
            const search = this.search;

            if (!search) return this.items;

            return this.items.filter(item => {

                return (item.points == search) ||
                    (item['start_at'] && this.dateHelper.getDateTimeTableFormat(item['start_at']).includes(search)) ||
                    (item['ends_at'] && this.dateHelper.getDateTimeTableFormat(item['ends_at']).includes(search));
            }
            );
        },
        numberOfPages() {
            return Math.ceil(this.items.length / this.itemsPerPage)
        },
        ...mapGetters(['getUser'])
    },
    async created() {
        this.$store.dispatch('loading/start');

        this.itemsPerPage = this.$store.state?.cache?.persistentInfo?.quizItemsPerPage ?? 5;

        await this.fetchMyQuiz();

        // console.table(this.items)
        this.loadingItems =  false;
        this.$store.dispatch('loading/end');

        this.$watch('itemsPerPage', async (value)=>{
            const key =  'quizItemsPerPage';
            await this.$store.dispatch('cache/getAndUpdatePersistentInfo', { key, value });
        });
    },
    mounted() {

    },
    methods: {
        isQuizPlayable(item) {
            switch (item.game_type_id) {
            case 2:
                return true;
            default:
                return false;
            }
        },
        openItem(item) {
            if (!this.isQuizPlayable(item)) {
                return;
            }

            const id = item.game_id;
            this.$router.push(`/app/pages/quiz/${id}`);
        },
        goToRanking(item) {
            const id = item.game_id;
            this.$router.push(`/app/pages/ranking?game=${id}`);
        },
        itemRowClass: function (item) {
            let classRow = '';

            // icon progress
            if (item.finished_at)
                classRow += ' progress-done';
            else if (item.started_at)
                classRow += ' progress-started';
            else
                classRow += ' progress-empty';


            if (this.isQuizPlayable(item)) {
                classRow += ' quiz_playable';
            }


            if (item.closed === 1) {
                classRow += ' quiz_closed';
            } else {
                if (!item.finished_at && !item.points) classRow += ' quiz_open_not_done';

                else classRow += ' quiz_open_done';
            }

            return classRow;
        },
        getIconProgress(item) {
            let icon = 'mdi-progress-pencil';

            if (item.finished_at) {
                icon = 'mdi-progress-check';
            } else if (item.started_at) {
                icon = 'mdi-progress-clock';
            }

            return icon;
        },
        async fetchMyQuiz() {
            try {
                const controller = new AbortController();
                const signal = controller.signal;

                const result = await QuizAPIService.getMyQuiz({signal});
                this.initialize(result.data);
            } catch (e) {
                // if(e.name !== 'TypeError')
                this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'))
            }
        },
        initialize($data) {
            this.items = $data.quiz ?? [];

            // this.pagination.totalItems = $data?.meta?.page?.total ?? 0;
        },
        updateItemsPerPage(number) {
            this.itemsPerPage = number
        },
        getImageURL(item) {
            const logo = item?.logo || 'default.png';
            return process.env.VUE_APP_API_ENDPOINT + 'media/imagesSimple?filename=games/logos/' + logo;
        }
    },
    destroyed() {

    }
}
</script>

<style lang="scss">
.quiz--item {
    display: grid;
    grid-template-columns: 100px auto 150px;
    column-gap: 10px;
    row-gap: 10px;

    border-radius: 10px;
    min-height: 2rem;
    border: 1px solid #828D99;
    padding: 5px 10px;

    .quiz--item__picture {
        display: grid;
        grid-template-columns: 100px auto;
        justify-content: start;
        align-items: center;
    }

    .quiz--item__info > *:last-child {
        display: flex;
        flex-wrap: wrap;

        & > div > span {
            display: block;
        }

        & > div:not(:last-child) {
            margin-right: 2rem;
        }
    }

    .quiz--item__actions {
        justify-self: right;
        align-self: center;

    }

    &.progress-empty {
        .quiz--item__actions-progress.v-icon.v-icon {
            color: #cfcfcf
        }
    }

    &.progress-started {
        .quiz--item__actions-progress.v-icon.v-icon {
            color: #13ca00
        }
    }

    &.progress-done {
        .quiz--item__actions-progress.v-icon.v-icon {
            color: #f0ab00
        }
    }

    &.quiz_playable {
        cursor: pointer;

        &.quiz_open_not_done {
            background-color: #d7a9ee;
            border: 1px solid #830051;
        }

        &.quiz_open_done {
            background-color: #f0ff4d;
            border: 1px solid #13ca00;
        }
    }

    &.quiz_closed, &:not(.quiz_playable) {
        background-color: #f2f2f2;
        border: 1px solid #bfbfbf;
    }
}


@media screen and (max-width: 600px) {
    .quiz--item {
        grid-template-columns: 100%;
    }
}
</style>
